import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import { Flex, Box } from '@chakra-ui/react';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import Section from '../components/Section';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "therapie-header"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    galleryImages: directusMediaCollection(name: {eq: "behandlungen"}) {
      ...LightboxGalleryGrid
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const CTASection = makeShortcode("CTASection");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <HeaderImage mdxType="HeaderImage">
  <Img fluid={props.data.headerImages.images[0].file.localFile.childImageSharp.fluid} mdxType="Img" />
    </HeaderImage>
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <Heading subtitle={'Physiotherapie Susanne Reiermann'} mdxType="Heading">
    Therapie Angebote
      </Heading>
      <hr></hr>
      <Flex justifyContent={'space-between'} flexWrap={'wrap'} mdxType="Flex">
        <Box width={['100%', '45%', '45%', '45%']} mdxType="Box">
    <Section heading1={'Manuelle'} heading2={'Therapie'} mdxType="Section">
        Die Manuelle Therapie ist eine spezielle Behandlungstechnik, die nach genauer Befundung der
        betroffenen Struktur zur Schmerzlinderung und Mobilisierung dient. Sie beinhaltet
        Traktions- und Gleittechniken sowie Entspannungs- und Dehntechniken, um alle gelenkumgebenden
        Strukturen und die Gelenke selbst wieder beweglich und belastbar zu machen.
    </Section>
        </Box>
        <Box width={['100%', '45%', '45%', '45%']} mdxType="Box">
    <Section heading1={'Behandlung von'} heading2={'Craniomandibulären Dysfunktionen (CMD)'} mdxType="Section">
        Hierbei werden funktionelle oder strukturelle Fehlregulationen der Muskeln und Gelenke des
        Kiefers behandelt. Dies können z.B. Schmerzen der Kaumuskulatur, Verlagerungen der Knorpelscheibe
        im Kiefergelenk, Bewegungseinschränkungen oder entzündliche bzw. degenerative Veränderungen sein.
    </Section>
        </Box>
        <Box width={['100%', '45%', '45%', '45%']} mdxType="Box">
    <Section heading1={'KG-ZNS auf der Basis von'} heading2={'Propriozeptiver Neuromuskulärer Fazilitation'} mdxType="Section">
      PNF fördert das Zusammenspiel zwischen Rezeptoren, Nerven und Muskeln, um eine gut koordinierte
      Bewegung zu erzielen. Sie wird v.a. bei neurologischen Krankheitsbildern angewandt, um
      alltägliche Fähigkeiten zu erhalten oder wieder neu zu erlernen. ZNS = zentrales Nervensystem
    </Section>
        </Box>
        <Box width={['100%', '45%', '45%', '45%']} mdxType="Box">
    <Section heading1={'Viszerale'} heading2={'Therapie'} mdxType="Section">
      Die Viszerale Therapie behandelt die inneren Organe, die durch Bindegewebe und Faszien miteinander
      verbunden sind. Organe bewegen sich in einem Rhythmus (Motilität) und weisen ebenso eine
      Beweglichkeit untereinander auf (Mobilität).<br />
      Über spezielle manuelle Griffe werden Motilität und Mobilität des jeweiligen Organs bestimmt
      und korrigiert, wodurch die Vitalität, die Funktionalität und die Versorgung des Organs
      positiv beeinflusst wird.
    </Section>
        </Box>
        <Box width={['100%', '45%', '45%', '45%']} mdxType="Box">
    <Section heading1={'Dreidimensionale Skoliosetherapie nach'} heading2={'Katharina Schroth'} mdxType="Section">
        Die dreidimensionale Skoliosetherapie nach Katharina Schroth ist eine spezielle Behandlungsmöglichkeit
        für Patienten mit Wirbelsäulenverkrümmungen, die sowohl Atemtechniken als auch haltungskorrigierende
        und muskelstabilisierende Elemente beinhaltet, die nach dem Erlernen zunehmend in den
        Alltag integriert werden sollen.
    </Section>
        </Box>
        <Box width={['100%', '45%', '45%', '45%']} mdxType="Box">
    <Section heading1={'Lokale Stabilität nach'} heading2={'Hamilton'} mdxType="Section">
        Diese Therapieform gehört zu den wenigen wissenschaftlich belegten Behandlungskonzepten bei
        Wirbelsäulenbeschwerden. Hierbei werden die lokalen wirbelsäulennahen Muskelstrukturen
        in den Fokus der Behandlung gerückt, um ein funktionierendes stabilisierendes Muskelkorsett
        aufzubauen und damit Rückfällen vorzubeugen.
    </Section>
        </Box>
        <Box width={'100%'} mdxType="Box">
    <Section heading1={'Craniosacrale '} heading2={'Therapie'} mdxType="Section">
        Die Craniosacrale Therapie ist eine alternativmedizinische ganzheitliche Behandlungsform, die sich aus der Osteopathie entwickelt hat. Sie beruht auf der Annahme, dass sich die rhythmischen Pulsationen der Gehirn- und Rückenmarksflüssigkeit auf die äußeren Gewebe und Knochen übertragen und somit zu ertasten sind.
        Eine genaue Befundung macht so das Aufspüren von körperinneren Blockaden möglich, die zu Einschränkungen, Schmerzzuständen und erhöhten Spannungen führen können.
        Mit Hilfe sanfter manueller Techniken werden die Einschränkungen behutsam gelöst.
    </Section>
        </Box>
      </Flex>
      <hr></hr>
      <h1>{`Behandlung`}</h1>
      <Lightbox images={props.data.galleryImages.images} thumbnails={props.data.galleryImages.thumbnails} fromApi thumbnailWidth={'180px'} mdxType="Lightbox" />
      <hr></hr>
      <CTASection header={'<span style="font-weight:bold;font-size: 2rem;line-height: 3rem;">Haben Sie Fragen? Rufen Sie uns an <br /> 02336 4759368 <br /> oder schreiben uns eine E-Mail</span>'} text={''} mailButtonText={'Kontaktformular'} showPhone={false} showAddress={false} mdxType="CTASection" />
    </Wrapper>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      